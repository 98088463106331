import React, { useEffect, useState } from "react"
import { SectionTitle } from "../../components/styled"
import JobContent from "../../components/JobContent"
import SearchJob from "../../components/SearchJob"
import { Seo } from "../../components/seo"
import useJobs from "../../hooks/useJobs"
import { languageData } from "../../translate/traduction"
import { graphql } from "gatsby"
const JobsTypesTemplate = props => {
  const dataJobs = useJobs("Spanish")
  const dataJobTypes = props.data.allStrapiType.nodes
  const [dataGroupType, setDataGroupType] = useState([])
  useEffect(() => {
    dataJobs.forEach(element => {
      for (let j = 0; j < element.types.length; j++) {
        if (dataJobTypes[0].name === element.types[j].name) {
          setDataGroupType(prevState => [...prevState, element])
        }
      }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return (
    <>
      <Seo
        title={`Empleos: ${dataJobTypes[0].name} | Mexico job information, career change, and employment information [QUICK GLOBAL MEXICO]`}
      />
      <section className="container jobs-listing">
        <div className="row" style={{ justifyContent: "center" }}>
          <div className="col-lg-9" style={{ padding: 0 }}>
            <div className="jobs-listing__search">
              <SectionTitle>
                Mexico Jobs, Employment and Career Change Information
              </SectionTitle>
              <hr className="u-line-bottom" />
              <div
                className="jobs-listing__search--box"
                style={{ padding: "0" }}
              >
                <SearchJob lang={"es"} />
              </div>
            </div>
            <div
              className="jobs-listing__recommended-jobs"
              style={{ padding: "0" }}
            ></div>
            <div className="jobs-listing__job-info">
              <SectionTitle>
                {languageData["es"].modal.jobs[dataJobTypes[0].name]}:{" "}
                {dataGroupType.length} resultado(s)
              </SectionTitle>
              <hr className="u-line-bottom" />
              {dataGroupType.map((jobs, i) => (
                <>
                  {jobs.types.map(types => (
                    <>
                      {dataJobTypes[0].name === types.name && (
                        <React.Fragment key={"jobContent_" + i}>
                          <JobContent jobs={jobs} lang={"es"} />
                        </React.Fragment>
                      )}
                    </>
                  ))}
                </>
              ))}
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default JobsTypesTemplate
export const query = graphql`
  query jobTypeEs($slug: String!) {
    allStrapiType(filter: { name: { eq: $slug } }) {
      nodes {
        name
      }
    }
  }
`
